import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

// components
import { Box, Button, Typography, Divider, Tooltip } from "@material-ui/core";
import { DesktopShop } from "../../../shop/components/DesktopShop";
import { HotelShopProgressBar } from "../../../shop/components/HotelShopProgressBar";
import { TravelOfferSelection } from "../TravelOfferSelection";
import {
  IContactInfo,
  TravelerSelectStep,
  HotelNeedToKnowPanel,
  ContactInfoForm,
  DesktopPopupModal,
  RoomDetails,
  CheckoutStepper,
  getCancellationPolicyInfo,
  ButtonWrap,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  IconName,
  Icon,
  TreesConfirmation,
  InformationalModal as TreesModal,
  HotelInformationalBanner,
  getIsFreeBreakfast,
  textConstants as halifaxTextConstants,
  CorpPolicyBanner,
  LaunchBanner,
  ApprovalReasonTextarea,
  ApprovalBanner,
  formatDateTimeWithTimeZone,
  VoidWindowNotice,
} from "halifax";
import {
  PriceBreakdown,
  PremierCollectionBookPassengerSelection,
  PaymentCard,
  PremierCollectionBookSummaryPanel,
  AGENT_FEE,
} from "../index";
import PremierCollectionCheckoutBenefits from "../PremierCollectionCheckoutBenefits";

// types, constants
import { DesktopPremierCollectionBookWorkflowConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import {
  CallState,
  CancellationPolicyEnum,
  CancellationReason,
  CheckInPolicy,
  ConnectionResultEnum,
  CorporateTravel,
  ITravelerStepErrors,
  InvalidEnum,
  LodgingCollectionEnum,
} from "redmond";
import {
  BOOK_BUTTON_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  CONTACT_INFO_HELPER_TEXT,
  NEED_TO_KNOW_TITLE,
  NEED_TO_KNOW_DETAILS,
  REQUIRED_DETAILS_TEXT,
  getCfarSecondaryText,
  TRAVELER_INFO_TITLE,
  AGENT_DETAILS_MODAL_TEXT,
  CFAR_POLICY_TITLE,
  CFAR_POLICY_SUBTITLE,
  TREES_MODAL_HEADER,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_SUBTITLE,
  SUBMIT_FOR_APPROVAL_TEXT,
} from "./textConstants";

// context
import { ClientContext } from "../../../../App";

// helpers, utils
import { fetchCustomerDetails } from "../../../../api/v0/customer/fetchCustomerDetails";
import {
  PATH_BOOK_CONFIRMATION,
  PATH_HOME,
  PATH_HOTEL,
} from "../../../../utils/paths";
import queryStringParser from "query-string";
import clsx from "clsx";
import {
  getHotelApprovalRequest,
  getShowLaunchBanner,
  isCaponeTenant,
  isCorpTenant,
} from "@capone/common";
import submitForApproval from "../../../../api/v0/book/book-flow/submitForApproval";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";

// styles
import "./styles.scss";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../../../shop/textConstants";
import {
  AVAILABLE,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { useExperimentsById } from "@capone/experiments";

export interface IDesktopPremierCollectionBookWorkflowProps
  extends RouteComponentProps,
    DesktopPremierCollectionBookWorkflowConnectorProps {
  isTreesModalExperiment: boolean;
  isTravelWalletOfferExperiment: boolean;
  isCreditAndOfferStackingExperimentV1: boolean;
  isTravelCreditHistoryExperiment: boolean;
}

export const DesktopPremierCollectionBookWorkflow = ({
  setContactInfo,
  priceQuote,
  priceQuoteCallState,
  schedulePriceQuote,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  combinedBookingSteps,
  reservation,
  history,
  isBookingValid,
  email,
  phoneNumber,
  allStepsCompleted,
  priceDifferenceAcknowledged,
  scheduleBook,
  isTravelerStepComplete,
  isContactStepComplete,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  priceQuoteErrors,
  confirmationDetailsErrors,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  isTravelWalletPaymentOnly,
  isCustomizePageEnabled,
  ancillaries,
  fetchTravelWalletCreditHistory,
  hasCfarAttached,
  hotelCfarQuote,
  largestEarnAccount,
  isTreesModalExperiment,
  isTravelWalletOfferExperiment,
  isCreditAndOfferStackingExperimentV1,
  isTravelCreditHistoryExperiment,
  selectedTraveler,
  opaquePayments,
  priceQuoteRequest,
  checkIn,
  checkOut,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  cancellationSummary,
}: IDesktopPremierCollectionBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, isAutoApprovalEnabled, policies } =
    clientContext;
  const isFirstStepCompletedInCheckout =
    isContactStepComplete && isTravelerStepComplete;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
    corpPrimaryTraveler: false,
  });
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [approvalRequestReason, setApprovalRequestReason] = useState<
    string | undefined
  >();

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: phoneNumber || "",
    email: email || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);

  const expState = useExperiments();

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const isApprovalsV2Enabled =
    useExperimentsById("corp-approvals-v2")?.variant === "m2";

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const onBookPremierCollection = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries: ancillaries,
          approvalRequestReason: approvalRequestReason,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries: ancillaries,
        });
  };

  const mergedPolicies = useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(isCaponeTenant(config.TENANT) ? PATH_HOME : PATH_HOTEL);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  const handleBookingInfoClick = useCallback(() => {
    setDetailsModalOpen(!detailsModalOpen);
  }, [detailsModalOpen]);

  // Corporate Travel
  const isApprovalRequired = !isAutoApprovalEnabled;
  const corporateTravel: CorporateTravel | undefined =
    roomInfoProduct && "corporateTravel" in roomInfoProduct.roomInfo
      ? roomInfoProduct.roomInfo.corporateTravel
      : undefined;

  const isInPolicy = Boolean(corporateTravel?.policyCompliance.isInPolicy);

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  const submitForApprovalRequest = getHotelApprovalRequest({
    chosenProduct,
    guests: selectedTraveler ? [selectedTraveler] : [],
    isApprovalRequired,
    roomInfoProduct,
    approvalRequestReason,
    priceQuote,
    selectedLodging,
    checkIn,
    checkOut,
    contactEmail: contactInfo?.email,
    priceQuoteRequest,
    opaquePayments,
    requestedAt: formatDateTimeWithTimeZone(new Date()),
  });

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (
      chosenProduct &&
      selectedLodging &&
      roomInfoProduct &&
      priceQuote &&
      approvalRequestReason &&
      selectedTraveler
    ) {
      try {
        if (isAutoApprovalEnabled) {
          const requestId = await submitForApproval(submitForApprovalRequest);
          requestId ? onBookPremierCollection() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(submitForApprovalRequest);
        }
      } catch (error) {
        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setApprovalRequestReason(e.target.value);
    },
    []
  );

  return (
    <Box
      className={clsx("desktop-premier-collection-book-workflow-root", {
        "with-progress-bar": isCustomizePageEnabled,
      })}
    >
      {detailsModalOpen && (
        <DesktopPopupModal
          open={detailsModalOpen}
          onClose={handleBookingInfoClick}
          className="desktop-premier-collection-book-details-modal"
        >
          <DesktopShop hideRightColumn hideProgressbar />
        </DesktopPopupModal>
      )}
      <CheckoutStepper
        steps={combinedBookingSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      >
        <LaunchBanner
          isBusinessLive={!showCorpLaunchBanner}
          onClick={() => {
            trackEvent({
              eventName: "complete_setup_clicked",
              properties: { entry: "traveler_activation_banner" },
            });
          }}
        />
      </CheckoutStepper>
      {isCustomizePageEnabled && (
        <Box className="hotel-book-progress-bar-wrapper">
          <HotelShopProgressBar useCustomizeStep={true} />
        </Box>
      )}
      <Box className="desktop-premier-collection-book-workflow-container">
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "premier-collection",
              {
                "free-breakfast":
                  selectedLodging?.lodgingCollection ===
                    LodgingCollectionEnum.Lifestyle &&
                  getIsFreeBreakfast(selectedLodging?.lodging.amenities, true),
              }
            )}
          >
            {selectedLodging?.lodgingCollection ===
              LodgingCollectionEnum.Lifestyle &&
            getIsFreeBreakfast(selectedLodging?.lodging.amenities, true) ? (
              <HotelInformationalBanner
                content={
                  <Tooltip
                    title={
                      <Typography className="tooltip-text">
                        {halifaxTextConstants.FREE_BREAKFAST_TOOLTIP}
                      </Typography>
                    }
                    classes={{
                      popper: "free-breakfast-tooltip-popper",
                      tooltip: "free-breakfast-tooltip-text",
                    }}
                  >
                    <Box className="free-breakfast-banner-content">
                      <Typography className="free-breakfast-content-text">
                        Free breakfast for cardholders
                      </Typography>
                      <Icon name={IconName.InfoCircle} />
                    </Box>
                  </Tooltip>
                }
                isMobile={false}
                isMap={false}
                iconName={IconName.FreeBreakfast}
              />
            ) : null}
            <PremierCollectionBookSummaryPanel
              className="b2b"
              actions={
                isAgentPortal ? (
                  <Typography
                    role="button"
                    variant="h6"
                    className="view-premier-collection-information"
                    onClick={handleBookingInfoClick}
                  >
                    {AGENT_DETAILS_MODAL_TEXT}
                  </Typography>
                ) : null
              }
            />
          </Box>

          {isTravelWalletOfferExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <>
              <Box className={clsx("checkout-template-description")}>
                <Typography
                  variant="h2"
                  className={clsx("checkout-template-title")}
                >
                  {TRAVELER_INFO_TITLE}
                </Typography>
              </Box>
              <Divider className="traveler-info-container-divider" />
            </>

            <PremierCollectionBookPassengerSelection
              disabled={disableEditTravelerInfo}
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
              combinedStep
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
            />
            <Divider className="traveler-info-container-divider" />
            <ContactInfoForm
              disabled={disableEditTravelerInfo}
              title={CONTACT_INFO_TITLE_NO_STEP}
              subtitle={CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
            />
            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save & Continue"
                onClick={() => {
                  if (isFirstStepCompletedInCheckout) {
                    schedulePriceQuote({
                      history,
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                      pollQuoteOnly: true,
                      ancillaries: ancillaries,
                    });
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                    });
                  } else {
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  setPriceQuote(null, null);
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  });
                  setSelectedRewardsAccountReferenceId(null);
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
          {hasCfarAttached ? (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "cancellation-details"
              )}
            >
              <Typography variant="h2">
                <Icon name={IconName.CheckShieldBlue} />
                {CFAR_POLICY_TITLE(hotelCfarQuote?.coveragePercentage)}
              </Typography>
              <Typography variant="body2">
                {CFAR_POLICY_SUBTITLE(hotelCfarQuote?.coveragePercentage)}
              </Typography>
            </Box>
          ) : cancellationPolicyInfo ? (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "cancellation-details"
              )}
            >
              <Typography variant="h2">
                {cancellationPolicyInfo.primaryText}
              </Typography>
              <Typography variant="body2">
                {showFree24HourCancel &&
                chosenProduct?.cancellationPolicy.CancellationPolicy ===
                  CancellationPolicyEnum.NonRefundable
                  ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : cancellationPolicyInfo.secondaryText}
              </Typography>
            </Box>
          ) : null}
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "need-to-know"
            )}
          >
            <HotelNeedToKnowPanel
              title={NEED_TO_KNOW_TITLE}
              subtitle={NEED_TO_KNOW_DETAILS}
              policies={mergedPolicies}
              fetchingPriceQuote={priceQuoteCallState === CallState.InProcess}
            />
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            <Box className="premier-collection-book-right-content">
              <CorpPolicyBanner
                productType="hotel"
                variant="base"
                corporateTravel={corporateTravel}
              />
              <Box className="payment-break-down-container">
                <Box
                  className={clsx(
                    "checkout-template-card-content-container",
                    "payment-break-down"
                  )}
                >
                  {!!roomInfoProduct && chosenProductIndex !== null && (
                    <RoomDetails
                      roomInfoProduct={roomInfoProduct}
                      productIndex={chosenProductIndex}
                      lodging={selectedLodging}
                      hideAmenities
                      hideAllAmenities
                      hideRoomBedDescription
                      hideRoomCapacity
                      showAmenitiesModal
                      roomsCount={1}
                    />
                  )}
                  <PriceBreakdown />
                </Box>
                {!isInPolicy && isCorpTenant(config.TENANT) && (
                  <>
                    <ApprovalReasonTextarea
                      value={approvalRequestReason ?? ""}
                      className="approval-reason"
                      onChange={handleReasonChange}
                      corporateTravel={corporateTravel}
                      productType="hotel"
                      limit={policies?.hotels.policies[0].maxPricePerNight}
                    />
                    <ApprovalBanner
                      isApprovalRequired={
                        isApprovalsV2Enabled
                          ? policies?.settings &&
                            policies.settings.isApprovalRequired
                          : !isAutoApprovalEnabled
                      }
                      isApprovalsV2Enabled={isApprovalsV2Enabled}
                    />
                  </>
                )}
                <Box className="confirm-book-button-container">
                  <Button
                    className={clsx("confirm-book-button", config.TENANT)}
                    disabled={
                      !isBookingValid ||
                      !allStepsCompleted ||
                      (isCorpTenant(config.TENANT) &&
                        !isInPolicy &&
                        !approvalRequestReason)
                    }
                    onClick={() =>
                      isInPolicy ||
                      isApprovalsV2Enabled ||
                      isCaponeTenant(config.TENANT)
                        ? onBookPremierCollection()
                        : onSubmitForApproval()
                    }
                  >
                    {isInPolicy ||
                    isApprovalsV2Enabled ||
                    !isApprovalRequired ||
                    isCaponeTenant(config.TENANT)
                      ? BOOK_BUTTON_TEXT
                      : SUBMIT_FOR_APPROVAL_TEXT}
                  </Button>
                </Box>
              </Box>
            </Box>
            {showFree24HourCancel && <VoidWindowNotice />}
            {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
              <PremierCollectionCheckoutBenefits
                variant={
                  selectedLodging?.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
                    ? "lifestyle-collection"
                    : "premier-collection"
                }
                largestEarnAccount={largestEarnAccount}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
